import React from "react"
import { Container, Row, Col } from "reactstrap";
import { Button } from "reactstrap";
import styled from "styled-components";
import { Link } from "gatsby";

import Layout from "../components/layout"
import SEO from "../components/seo"
import skulls from "../images/Skulls.jpg";
import bannermedical from "../images/banner/page-banner/banner-medicals.png";
import solidImplant from "../images/products/solidImplant.jpg";
import meshImplant from "../images/products/meshImplant.jpg"
import implantmodel2 from "../images/implant2.jpg"
import printmodel from "../images/modelprint.jpg"
import screw from "../images/implant-screw.jpg"
import interlink from "../images/interlink-plate.jpg";
import peekmodel from "../images/Xilloc-PEEK-Implant.png";
import meshplate from "../images/meshplate.jpg";
import combed from "../images/combed.png";
import memphis from "../images/memphis.png";
import scanlysis2 from "../images/analysis2.jpg"
import mda from "../images/mda_logo.png";
import mdanew from "../images/MDA(new).png";
import gdplong from "../images/gdpmdlong.png";
import anatomical from "../images/products/anatomical.jpg";
import peekskull from "../images/PeekSkull.png";
import specificImplant from "../images/products/specificImplant.jpg";

import Banner from "../components/Banner";
import LinkButtonSecondary from "../components/buttons/LinkButtonSecondary";
import LinkhrefButtonSecondary from "../components/buttons/LinkhrefButtonSecondary";
import BackgroundSection from "../components/BackgroundSection";
import CardSimple from "../components/CardSimple";
import CardBorder from "../components/CardBorder";

import { FaArrowRight } from "react-icons/fa";
import LinkPlain from "../components/buttons/LinkPlain";

const Section = styled.section`
  padding: 60px 0;
`
const Box = styled.div`
  position: relative;
  padding: 4px;
  /* border-bottom: solid #17a99e 1px; */
  p{
    color: #000;
    /* height: 100px; */
  }
  h5{
    height: 50px;
  }
`
const Box2 = styled.div`
  position: relative;
  padding: 4px;
  /* height: 580px; */
  border-bottom: solid #17a99e 1px;
  p{
    color: #000;
    /* height: 100px; */
  }
`
const CertificationDiv = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #fff;
  h3{
    flex: 0 0 100%;
    text-align: center;
  }
`
const CertificateBox = styled.div`
  flex-flow: column;
  justify-content: center;
  text-align: center;
`
const CertifiedImg = styled.img`
  background-color: #fff;
  width: auto;
  height: 150px;
  object-fit: contain;
  padding: 10px;
  margin: 0 5px;
`

const medicalcontents = [
  {
    title: "Design and Analysis",
    desc:
          <>
            <p>
              Medical imaging data is converted to a 3d model using specific medical software for 3d visualisation and simulation. 
              The implant is designed based on patientʼs CT scan data for better aesthetic and fitting purposes. 
              We develop custom made implant and devices through digital manufacturing.
              {/* The study covers bone cutting and movement, implant fitting analysis and distractor device simulation.  */}
              {/* The virtual Modeling allows surgeons to see anatomic structures inside and out and bringing safety and practicality to procedures.  */}
            </p>
            {/* <p>
              Medical imaging data is converted to 3D model data for better visualization and planning visibility.
            </p> */}
          </>,
    image: <img src={scanlysis2}></img>,
  },
  {
    title: "Anatomical Model",
    desc: 
          <>
            <p>
              We produce 3Dimensional models that complement traditional imaging and help surgeons, doctors and patients.
              The models is used for surgical planning thus reducing surgical risks. 
            </p>
            {/* <p>
              Specific Anatomical Models are printed through 3D Printing technology for surgical planning procedures and also practising purposes for complicated cases that require detailed planning and accuracy.
            </p> */}
          </>,
    image: <img src={anatomical}></img>,
  },
  {
    title: "Patient Specific Implant",
    desc: 
          <>
            <p>
              Our LOGIC implant is made for the perfect shape for each patient. 
              LOGIC implants are designed to replicate the exact anatomy of the patient thus having the perfect fit for unmatched functional and aesthetic outcomes. 
              Our titanium mesh implant provides lightness, flexibility, resistance and durability
            </p>
            {/* <p>
              The customised implant is designed specifically to attach to the existing bone with self drilled titanium screws, replacing defective bone lost from congenital condition, disease, or trauma.
            </p> */}
          </>,
    image: <img src={specificImplant}></img>,
  }
]

const medicitems = [
  {
    title: "Titanium Mesh implant",
    price: <ul className="contextDivSM">
            <li>100*100</li>
            <li>150*150</li>
            <li>200*200</li>
            <li>250*250</li>
          </ul>,
    desc: "",
    image: meshImplant
  },
  {
    title: "Titanium Solid Implant",
    price: <ul className="contextDivSM">
            <li>100*100</li>
            <li>150*150</li>
            <li>200*200</li>
            <li>250*250</li>
          </ul>,
    desc: "",
    image: solidImplant
  },
  {
    title: "Peek Implant",
    price: <ul className="contextDivSM">
            <li>100*100</li>
            <li>150*150</li>
            <li>200*200</li>
          </ul>,
    desc: "",
    image: peekmodel
  },
  {
    title: "Titanium Interlink Plate",
    price: <ul className="contextDivSM">
              <li>15mm(Set with Screws) <p></p></li>
              <li>17mm(Set with Screws) <p></p></li>
            </ul>,
    desc: "",
    image: interlink
  },
  {
    title: "Titanium Self Drilling Screw",
    price: <ul className="contextDivSM">
            <li>2.0*4mm</li>
            <li>2.0*5mm</li>
            <li>2.0*7mm</li>
            <li>2.0*9mm</li>
          </ul>,
    desc: "",
    image: screw
  },
  {
    title: "Titanium Mesh Plate",
    price: <ul className="contextDivSM">
            <li>100*100</li>
            <li>150*150</li>
            <li>200*200</li>
            <li>250*250</li>
          </ul>,
    desc: "",
    image: meshplate
  }
]

const certificates = [
  {
    image: gdplong,
    title: "",
    lincense: "MYG7172851"
  },
  {
    image: mdanew,
    title: "",
    lincense: "KP21802180117"
  },
]


const MedicalPage = ({ data }) => (
  <Layout>
    <SEO title="Medical" />
      <Banner src={bannermedical} alt="banner">

          <h3>
            Implant Online Order
          </h3>
          <p>
            Provide us your CT scan to print a customised implant.
          </p>
          {/* <LinkButtonSecondary toLink="/medical-upload">Upload CT Scan <FaArrowRight/></LinkButtonSecondary> */}


      </Banner>
    <Section>
      <Container>
        <h3>Medical</h3>
        <p>
          We are able to provide 3D data visualization and simulation information for surgical planning prior to operation. We also specialise in 3D anatomical models to aid in cases that require a multidisciplinary approach. Our Patient Specific Implant (PSI) process we have developed, we have eliminated In-situ as well as trial and error implant dimensioning. This has resulted into shortened operation time and reduce in surgical risk or operation error.
        </p> 

        <Row>
          {medicalcontents.map((content) => (
            <Col md="4">

            <CardSimple title={content.title} image={content.image} description={content.desc}></CardSimple>

          </Col>  
          ))}
        </Row>
      </Container>
    </Section>
    <BackgroundSection image={memphis}>
      <Container>
        <CertificationDiv>
        <h3>
            We are registered with
        </h3>
            {certificates.map((item) => (
              <CertificateBox>
                <CertifiedImg src={item.image} />
                <h5>{item.lincense}</h5>
              </CertificateBox>
            ))}
        </CertificationDiv>
      </Container>
    </BackgroundSection>
  <div style={{backgroundColor: "#d8d8d8"}}>
  <BackgroundSection image={combed} id="medic">
      <Container>
          <h3 style={{color: "#000"}}>
            Medical Implant and Devices
          </h3>
        {/* <Row>
          {medicitems.map((item) => (
            <Col md="4">
              <CardBorder className="ListedItem" image={item.image} title={item.title} description="Sizes That are Available : ">
                {item.price}
                <LinkPlain to="about/#contact">Request for Quotation</LinkPlain>
              </CardBorder>
            </Col>
          ))}
        </Row> */}
        <Row>
            {data.allStrapiMedicals.edges.map(document => (
              <Col md="4">
                <div style={{display: "flex", height: "100%",}}>
                  <CardBorder className="ListedItem" image={document.node.image.publicURL} title={document.node.title} description={document.node.description}>
                    
                    <div className="">
                      <LinkPlain to="about/#contact">Request for Quotation</LinkPlain>
                    </div>
                  </CardBorder> 
                </div>
              </Col>
            ))}
          </Row>
      </Container>
  </BackgroundSection>
  </div>
    {/* </Section> */}
  </Layout>
)

export default MedicalPage

export const pageQuery = graphql`
                    query MedicalQuery  {
                      allStrapiMedicals{
                        edges {
                          node {
                            title
                            description
                            image{
                              publicURL
                            }
                          }
                        }
                      }
                    }
`